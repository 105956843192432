<template>
  <div class="right-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'right-content'
}
</script>

<style lang="scss" scoped>
.right-content{
  margin-left: 10px;
  height: 100%;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  flex: 1;
  overflow: auto;
}
</style>
