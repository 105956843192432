<template>
  <div class="wrapper">
    <tree-to-vliew ref="treeView" :isDeep="false"></tree-to-vliew>
    <right-content>
      <div class="right-content_head">
        <el-button @click="add">创建项目部</el-button>
        <el-button @click="exportData">导出项目部</el-button>
      </div>
      <div class="right-content_table">
        <span class="title">{{ companyName }}: 项目部 {{ total }} 个</span>
        <div class="table">
          <my-table
            @currentPage="currentPage"
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
          >
            <el-table-column
              width="300px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button
                    icon="iconfont icon-chakan-copy1"
                    @click="check(row)"
                    >查看</el-button
                  >
                  <el-button icon="iconfont icon-bianji1" @click="change(row)"
                    >编辑</el-button
                  >
                  <el-button icon="iconfont icon-shanchu11" @click="del(row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from "../../components/MyTable";
import TreeToVliew from "../commonViews/TreeToVliew";
import { delInformation, subordinateList,exportJunior } from "@/api";
import RightContent from "../commonViews/RightContent";
import {exportFun} from '@/utils/params'

export default {
  name: "supervision",
  components: { RightContent, TreeToVliew, MyTable },
  async created() {
    await this.renderData();
  },
  //keepAlive生命周期,让缓存的组件每次更新后执行函数
  async activated () {
    await this.renderData();
  },
  methods: {
    exportData(){
      const param = {
        type: 2,
        company_id: localStorage.getItem("company_id")
      }
      exportFun(exportJunior,param,"exportJunior")
    },
    currentPage(current) {
      this.page = current;
      this.renderData();
    },
    async renderData() {
      const res = await subordinateList({
        type: 2,
        other_id: localStorage.getItem("company_id"),
        page:this.page
      });
      if (res.status === 200) {
        this.tableInfo = res.data.data;
        console.log(this.tableInfo)
        this.total = res.data.data.total

      }
    },
    add() {
      this.$router.push({
        path: "/company/company-create",
      });
    },
    check(row) {
      this.$router.push({
        path: "/company/company-detail",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    async del({ id }) {
      try {
        const flag = await this.$open({ msg: "确定删除项目部？" });
        if (flag) {
          const res = await delInformation({
            type:2,
            del_type: 2,
            id,
          });
          if (res.status === 200) {
            this.$success("删除成功");
            await this.renderData();
            this.$refs.treeView.renderTreeList();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    change(row) {
      this.$router.push({
        path: "/company/company-change",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
  },
  data() {
    return {
      total:0,
      companyName:JSON.parse(localStorage.getItem("userInfo")).name,
      tableInfo: {},
      requestParam: {},
      page: 1,
      colConfigs: [
        { prop: "name", label: "项目部名称" },
        { prop: "people_name", label: "项目部管理员" },
        { prop: "phone_number", label: "联系电话" },
        { slot: "option" }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .right-content_head {
    margin-bottom: 20px;
    button {
      background-color: red;
      color: #fff;
      padding: 10px 25px;
    }
  }
  .right-content_table {
    .table {
      margin: 20px 0;
    }
    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
