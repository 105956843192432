<template>
  <left-content>
    <div class="tree">
      <el-tree
        class="filter-tree"
        @node-click="nodeClick"
        :data="treeData"
        default-expand-all
        ref="tree"
      >
      </el-tree>
    </div>
  </left-content>
</template>

<script>
import { getAllChildrenList } from "@/api";
import LeftContent from "./LeftContent";
export default {
  name: "TreeToVliew",
  components: { LeftContent },
  props: {
    //是否显示部门列表
    isDeep: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const {name,id} = JSON.parse(localStorage.getItem("userInfo"))
    return {
      treeData: [
        {
          label: name,
          id,
          flag:"company_id",
          children: [],
        },
      ],
    };
  },
  methods: {
    nodeClick(row) {
      const { flag, id,label } = row;
      let requestData = {};
      requestData[flag] = id;
      requestData.page = 1;
      requestData.label = label;
      this.$emit("request", requestData);
    },
    async renderTreeList(){
      const res = await getAllChildrenList({
      type: 2,
    });
    const department = res.data.data.department;
    const dataTree = (data) => {
      return data.map((c, index) => {
        let tree = {
          label: c.name,
          id: c.id,
          flag: "department_id",
        };
        if (this.isDeep && c.sentry && c.sentry.length > 0) {
          tree.children = c.sentry.map((item) => {
            return  {
              label: item.name,
              id: item.id,
              flag: "sentry_id",
            };
          });
        }
        return tree;
      });
    };
    this.treeData[0].children = dataTree(department);
    }
  },
  async activated(){
    this.renderTreeList();
  },
  async created() {
    await this.renderTreeList();
    //获取总包部下面所有数据
    const firstParams = {
      flag: "company_id",
      id: localStorage.getItem("company_id"),
      label:JSON.parse(localStorage.getItem("userInfo")).name
    };
    this.nodeClick(firstParams);
  },
};
</script>

<style lang="scss" scoped>
.filter-tree {
  margin: 15px;
  background-color: #ffffff;
  font-size: 20px;
}
</style>
