<template>
  <div class="table-page">
    <el-table
      border
      :data="tableData"
      :header-cell-style="{ background: '#eee', color: '#606266' }"
    >
      <template v-for="(config, index) in colConfigs">
        <slot v-if="config.slot" :name="config.slot"></slot>
        <el-table-column
          :key="index"
          v-if="config.prop === 'type'"
          align="center"
          v-bind="config"
        >
          <template slot-scope="{ row }">
            <span style="color: #ff3b30" v-if="row.type === 2">未处理</span>
            <span style="color: #ffc300" v-else-if="row.type === 1">处理中</span>
            <span style="color: #07c160" v-else-if="row.type === 3">已处理</span>
          </template>
        </el-table-column>
        <el-table-column
          :key="index"
          v-else-if="config.prop === 'created_at'"
          v-bind="config"
          align="center"
        >
          <template slot-scope="{ row }">
            <p>{{Moment(row.created_at).format('YYYY-MM-DD hh:mm:ss')}}</p>
          </template>
        </el-table-column>
        <el-table-column
          :key="index"
          v-else
          align="center"
          v-bind="config"
        ></el-table-column>
      </template>
    </el-table>
    <div class="page">
      <el-pagination
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totalNum"
        @current-change="currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "MyTable",
  data(){
    return {
      Moment: moment,
      tableData:[],
      pageSize: 10,
      totalNum:0,
    }
  },
  methods: {
    currentPage(current){
      this.$emit("currentPage",current)

    }
  },

  watch:{
    tableInfo:{
      handler(val) {
        this.tableData = val.data;
        this.pageSize = val.per_page
        this.totalNum = val.total;
      },
      immediate:true,
      deep:true
    },
  },
  props: {
    tableInfo: {
      type: Object,
    },
    colConfigs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
span {
  background: #e0ffef;
  border-radius: 15px;
  padding: 15px 10px;
  font-size: 14px;
  font-family: PingFang SC;
}
</style>
